.dropdown-container {
    position: relative;
    /*display: inline-block;*/
}

/*.dropdown-container select {*/
/*    padding: 5px 3px;*/
/*    font-size: 16px;*/
/*    color: #333;*/
/*    background-color: #fff;*/
/*    cursor: pointer;*/
/*    min-width: 200px;*/
/*}*/

.overlay-input {
    position: absolute;
    top: 0;
    left: 0;
    /*width: calc(100% - 5px);*/
    /*height: calc(100% - 2px);*/
    /*border: none;*/
    /*outline: none;*/
    /*background-color: darksalmon;*/
}

.editing {
    color: transparent;
    text-shadow: 0 0 0 black;
}

.checkmark {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    cursor: pointer;
    color: white;
}