#proposal-panel {
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: #333;
    color: #fff;
    border-radius: 10px;
    padding: 40px 20px 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    max-width: 500px;
}

#back-to-overview-button {
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
}