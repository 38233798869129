#hold-change-dialog {
    display: block;
    border: solid black;
    background: #333;
    color: white;
    padding: 40px 20px 20px 20px;
    text-align: center;
}

/*#proposal-review-box > div {*/
/*    padding-bottom: 3px;*/
/*}*/

#close-hold-dialog {
    position: absolute;
    top: 10px;
    left: 10px;
}